import {EventMonitorStoredObject, OddsObject} from "../../../@types/response";
import {getBetfairUrl} from "../../../store/utils/events";

export default function OddsComparison({
                                           odds,
                                           event,
                                           marketId
                                       }: { odds: OddsObject[], event: EventMonitorStoredObject, marketId: number }) {
    return (
            <div className='flex justify-between'>
                {odds.map((odd: OddsObject, idx) => {
                    const betfairUrl = event.marketCap && getBetfairUrl(event?.marketCap?.[marketId]?.bMarketId?.toString(), event?.marketCap?.[marketId]?.[odd.signId]?.toString())
                    return <div
                            key={idx}
                            data-id={`comparison-odd-${odd.signId}`}
                            data-value={odd.odd.toFixed(2)}
                            className={`p-2 text-center w-1/${odds.length} ${betfairUrl && 'cursor-pointer'}`}
                            onClick={() => {
                                betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660")
                            }}
                    >
                        {odd.odd.toFixed(2)}
                    </div>
                })}
            </div>
    )
}

