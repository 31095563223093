import {
    COMPARISONS_PRIORITY,
    EXTRA_MARKETS,
    GET_EVENT_MONEY_LINE_SUCCESS,
    GET_MARKET_CAP_SUCCESS,
    GET_SPORTS_SUCCESS,
    MARKETS,
    PLAYER_MARKETS,
    SET_ALIVE,
    SET_BOOKMAKERS,
    SET_EVENT_MONEY_LINE,
    SET_MARKET_CAP
} from '../../constants/CommonConstants';
import {LOGIN, LOGIN_SUCCESS, LOGOUT_SUCCESS} from '../../constants/LoginConstants';
import {AnyAction} from 'redux';
import {CommonReducer as State} from '../../@types/reducers';
import {CLIENT_IS_NOT_LOGGED_IN} from '../../constants/ErrorConstants';
import {GET_USER_INFO, GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS} from "../../constants/UserConstants";
import {BookmakerObject, MarketCapData, MarketCapObject, MoneyLineData, MoneyLineObject} from "../../@types/response";

const initialState: State = {
    bookmakers: {},
    comparisons: [],
    sports: {},
    client: undefined,
    eventBookmaker: [],
    getSessionChecked: false,
    markets: MARKETS,
    playerMarkets: PLAYER_MARKETS,
    extraMarkets: EXTRA_MARKETS,
    permissions: [],
    eventsMoneyLine: {},
    marketCap: {}
};

const commonReducer = (state = initialState, action: AnyAction): State => {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return {
                ...state,
                client: action.payload,
            };
        }
        case GET_USER_INFO: {
            return {
                ...state,
                getSessionChecked: initialState.getSessionChecked
            };
        }
        case GET_USER_INFO_SUCCESS: {
            return {
                ...state,
                client: action.payload ?? initialState.client,
                getSessionChecked: true,
            };
        }
        case GET_USER_INFO_ERROR: {
            return {
                ...state,
                client: initialState.client,
                getSessionChecked: true
            };
        }
        case SET_BOOKMAKERS: {
            const books: BookmakerObject[] = Object.values(action.payload)
            const comparisons: BookmakerObject[] = books
                .filter((b: BookmakerObject) => b.isComparison)
                .sort((a: BookmakerObject, b: BookmakerObject) => COMPARISONS_PRIORITY[a.id.toString()] - COMPARISONS_PRIORITY[b.id.toString()])
            const eventBookmaker = books.filter((item: BookmakerObject) => item.details >= 0)
                .sort((a: BookmakerObject, b: BookmakerObject) => {
                    if (a.details === b.details) {
                        return b.name > a.name ? -1 : 1
                    } else {
                        return b.details - a.details
                    }
                })
                .map((item: any) => item.id)
            return {
                ...state,
                bookmakers: action.payload,
                comparisons: comparisons,
                eventBookmaker: eventBookmaker
            };
        }
        case GET_SPORTS_SUCCESS: {
            return {
                ...state,
                sports: action.payload
            };
        }
        /*resetting*/
        case LOGIN:
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                client: initialState.client,
                permissions: initialState.permissions
            };
        }
        case CLIENT_IS_NOT_LOGGED_IN:
            return {
                ...state,
                client: initialState.client,
                permissions: initialState.permissions,
            };
        case SET_ALIVE:
            let bookmaker: BookmakerObject = Object.assign({}, state.bookmakers[action.payload.bookmaker])
            const productId: string = action.payload.product
            if (productId && bookmaker.products) {
                bookmaker['products'][productId] = action.payload.alive
            }
            return {
                ...state,
                bookmakers: Object.assign({}, state.bookmakers, {[action.payload.bookmaker]: bookmaker}),
            };
        case GET_EVENT_MONEY_LINE_SUCCESS: {
            return {
                ...state,
                eventsMoneyLine: action.payload
            };
        }
        case SET_EVENT_MONEY_LINE: {
            const update = action.payload.reduce((a: MoneyLineObject, v: MoneyLineData) => ({...a, [v.k]: v}), {})
            return {
                ...state,
                eventsMoneyLine: Object.assign({}, state.eventsMoneyLine, update)
            };
        }
        case GET_MARKET_CAP_SUCCESS: {
            return {
                ...state,
                marketCap: action.payload
            }
        }
        case SET_MARKET_CAP: {
            const update = action.payload.reduce((a: MarketCapObject, v: MarketCapData) => ({...a, [v.k]: v}), {})
            return {
                ...state,
                marketCap: Object.assign({}, state.marketCap, update)
            }
        }
        default:
            return state;
    }
};

export default commonReducer;
