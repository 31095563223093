import {
    AlertPlayerObject,
    BookmakerData,
    EventStoredObject,
    PlayerMarketData,
    PlayerSettingsObject
} from '../../../@types/response';
import {useSelector} from 'react-redux';
import {getBookmakers} from '../../../store/selectors/getBookmakers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getSettings} from '../../../store/selectors/player/getSettings';
import {faChartArea, faEye, faSearch} from '@fortawesome/free-solid-svg-icons';
import {AVERAGE, AVERAGE_2, BETFAIR, PINNACLE} from '../../../constants/CommonConstants';
import Tooltip from '../common/Tooltip';
import {getPlayerMarkets} from "../../../store/selectors/getPlayerMarkets";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {useContext, useState} from "react";
import {ModalsContext} from "../modals/ModalsContext";
import {getBetfairChartUrl} from '../../../store/utils/playerUtils';
import {getBetfairUrl} from '../../../store/utils/events';
import {toMoney} from '../../../store/utils/commonUtils';
import Odds from './Odds';
import {EventModalProps} from '../../../@types/inputs';
import TooltipPlayerOdds from '../common/TooltipPlayerOdds';

export default function PlayerItem({alert, event}: { alert: AlertPlayerObject, event: EventStoredObject }) {

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);
    const [showSelectionChart, setShowSelectionChart] = useState<boolean>(false);
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const markets: PlayerMarketData = useSelector(getPlayerMarkets);
    const betfairChartUrl = getBetfairChartUrl(alert);
    const betfairUrl = getBetfairUrl(alert.bMarketId, alert.bSelectionId)
    const hasBetfair = betfairChartUrl != null;
    const startDt = new Date(event.date)

    const label = `${event.category} - ${event.tournament}`
    const settings: PlayerSettingsObject = useSelector(getSettings);
    let best = 'border-monitor-margin-l2'
    if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level1) {
        best = 'border-monitor-margin-l0'
    } else if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level0) {
        best = 'border-monitor-margin-l1'
    }
    return (
            <tr
                    id={`alert-${alert.playerId}-${alert.marketId}-${alert.sbv}-${alert.signId}`}
                    key={`alert-${alert.playerId}-${alert.marketId}-${alert.sbv}-${alert.signId}`}
                    className={`border-b-2 tracking-[1px] border-black text-monitor-secondary hover:bg-gray-600
            ${alert.comparisonType === AVERAGE ? 'bg-monitor-average' : ''}
            ${alert.comparisonType === AVERAGE_2 ? 'bg-monitor-average2' : ''}
            ${alert.comparisonType === BETFAIR ? 'bg-monitor-betfair' : ''}
            ${alert.comparisonType === PINNACLE ? 'bg-monitor-pinnacle' : ''}`
                    }>
                {/* event_name_player */}
                <td className='items-center 2xl:pl-28 pl-16 relative'>
                    <div className={'border-r-[5px] absolute -left-2 2xl:mx-14 mx-8 2xl:h-12 h-9 ' + best}>
                    </div>
                    <Tooltip isEvent={true} tooltipText={`${event.name} ${label}`}>
                        <div className='flex flex-col py-1'>
                            <div className='text-left text-white truncate 2xl:text-monitorBase text-[0.68rem] font-medium'>
                                {event.name && `[${event.name}]`} {alert.name}
                            </div>
                            <div className='text-left 2xl:text-monitorBase truncate text-[0.68rem]'
                                 data-id='competition'>
                                {label.substring(0, 32)}
                            </div>
                        </div>
                    </Tooltip>
                </td>
                {/*search*/}
                <td>
                    <span
                            className='cursor-pointer'
                            onClick={() => {
                                setModalsDataCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, {
                                    event: event,
                                    playerId: alert.playerId,
                                    marketId: alert.marketId
                                });
                                setOpenedModalsCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, true);
                            }}
                    >
                        <FontAwesomeIcon icon={faSearch}/>
                    </span>
                </td>
                {/* data */}
                <td className='py-1 px-3 text-center' data-id='player-time'
                    data-value={startDt.getTime()}>
                    {[startDt.getDate().toString().padStart(2, '0'),
                        (startDt.getMonth() + 1).toString().padStart(2, '0')
                    ].join('/')}&nbsp;&nbsp;
                    {`${startDt.getHours().toString().padStart(2, '0')}:${startDt.getMinutes().toString().padStart(2, '0')}`}
                </td>
                {/* margin */}
                <td
                        className='py-1 px-2 text-center text-white bg-monitor-margin'
                        data-id='margin'
                        data-value={alert.margin.toFixed(1) || '-'}
                >
                    <dd>{alert.margin.toFixed(1) || '-'}</dd>
                </td>
                {/* market */}
                <td
                        className='cursor-pointer py-1 px-3 text-center'
                >
                    <div
                            onClick={() => {
                                const _event: EventModalProps = {
                                    id: event._id,
                                    name: event.name,
                                    sportId: event.sportId
                                };
                                setModalsDataCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, {
                                    event: _event,
                                    marketId: alert.marketId,
                                    signId: alert.signId
                                });
                                setOpenedModalsCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, true);
                            }}
                    >{markets[alert.marketId].name}</div>
                </td>
                {/* sign */}
                <td
                        className='cursor-pointer py-1 px-3 text-center'
                >
                    <div
                            onClick={() => {
                                const _event: EventModalProps = {
                                    id: event._id,
                                    name: event.name,
                                    sportId: event.sportId
                                };
                                setModalsDataCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, {
                                    event: _event,
                                    marketId: alert.marketId,
                                    signId: alert.signId
                                });
                                setOpenedModalsCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, true);
                            }}
                    >{markets[alert.marketId].outcomes[alert.signId].name + (alert.sbv !== '#' ? (' ' + alert.sbv) : '')}</div>
                </td>
                {/* bookmaker */}
                <td className='py-1 px-3 text-center tracking-normal font-semibold'>
                    {bookmakers[alert.bookmakerId]?.name || alert.bookmakerId + ' not found'}
                </td>
                {/* odds */}
                <td className='px-2 text-center relative btn-on-over'>
                    <Odds backOdd={alert.backOdd} alert={alert} event={event}/>
                </td>
                {/* comparison */}
                <td
                        className='px-2 text-center relative btn-on-over cursor-pointer'
                        onClick={() => {
                            betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660")
                        }}
                >
                    <TooltipPlayerOdds eventId={alert.eventId} marketId={alert.marketId} playerId={alert.playerId}
                                       signId={alert.signId} sbv={alert.sbv}>
                        <div className={`p-1 rounded-md mx-1 text-center cursor-pointer w-full`}>
                            {alert.comparisonOdd}
                        </div>
                    </TooltipPlayerOdds>
                </td>
                {/*moneyline*/}
                <td className="text-right 2xl:text-xs text-[.6rem]">
                    <div>{alert.marketTV ? toMoney(alert.marketTV) : ""}</div>
                    <div>{alert.runnerTV ? `[${toMoney(alert.runnerTV)}]` : ""}</div>
                </td>
                {/*betfair cap*/}
                <td className={`cursor-pointer text-center ml-2 ${hasBetfair}`}>
                    <FontAwesomeIcon
                            className={hasBetfair ? "" : "text-gray-400"}
                            icon={faChartArea}
                            onMouseEnter={() => hasBetfair && setShowSelectionChart(true)}
                            onMouseLeave={() => hasBetfair && setShowSelectionChart(false)}
                    />
                    {showSelectionChart && hasBetfair && (
                            <img
                                    className="fixed"
                                    alt="MarketCap"
                                    style={{zIndex: 9, left: 0, right: 0, top: 0, bottom: 0, margin: "auto"}}
                                    src={betfairChartUrl}
                            />
                    )}
                </td>
                {/*source*/}
                <td className='text-center' data-id='comparison' data-value={alert.comparisonType}>
                    <span className='border-2 border-player-secondary font-semibold  px-1 '>
                        {alert.comparisonType === BETFAIR ? 'BF' : ''}
                        {alert.comparisonType === AVERAGE ? 'AVG' : ''}
                        {alert.comparisonType === AVERAGE_2 ? 'AVG2' : ''}
                        {alert.comparisonType === PINNACLE ? 'PIN' : ''}
                    </span>
                </td>
                {/*actions*/}
                <td className='cursor-pointer'
                    onClick={() => {
                        setModalsDataCallback(MODALS_TYPES.HIDE_PLAYER_MODAL, {alert});
                        setOpenedModalsCallback(MODALS_TYPES.HIDE_PLAYER_MODAL, true);
                    }}
                >
                    <FontAwesomeIcon icon={faEye}/>
                </td>
            </tr>
    )
}