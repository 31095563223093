import {SetOrderAction} from '../../../store/actions/monitor/SetOrderAction';
import {useDispatch, useSelector} from 'react-redux';
import {MonitorSettingsObject} from '../../../@types/response';
import {getSettings} from '../../../store/selectors/monitor/getSettings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {OrderConstants} from "../../../constants/MonitorConstants";
import {getMonitorsLegth} from "../../../store/selectors/monitor/getMonitorsLegth";


export default function MonitorTable({children}: { children?: any }) {

    const settings: MonitorSettingsObject = useSelector(getSettings);
    const showedAlerts: number = useSelector(getMonitorsLegth);
    const dispatch = useDispatch();

    return <div className='w-full inline-block'>
        <table className='text-table-primary w-full table-fixed'>
            <colgroup>
                {/*event_name*/}
                <col className='w-auto'/>
                {/*search*/}
                <col className='w-8'/>
                {/*date*/}
                <col className='w-[8%]'/>
                {/*margin*/}
                <col className='w-16'/>
                {/* arrow */}
                <col className='w-8'/>
                {/*market*/}
                <col className='w-[8%]'/>
                {/*bookmaker*/}
                <col className='w-[8%]'/>
                {/*odd*/}
                <col className='w-[10%]'/>
                {/*comparison*/}
                <col className='w-[10%]'/>
                {/*betfair cap*/}
                <col className='w-24'/>
                {/*betfair chart*/}
                <col className='w-16'/>
                {/*moneyline*/}
                <col className='w-24'/>
                {/*source*/}
                <col className='w-16'/>
                {/*actions*/}
                <col className='w-16'/>
            </colgroup>
            <thead>
            <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                {/*event_name + search*/}
                <th className='2xl:pl-28 pl-16 py-1 text-left'>Event/Competition</th>
                <th/>
                {/*date*/}
                <th className='py-1 text-center cursor-pointer relative' onClick={() => {
                    settings.order !== OrderConstants.DATE && dispatch(SetOrderAction(OrderConstants.DATE))
                }}>Date {settings.order === OrderConstants.DATE &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                {/*margin + arrow*/}
                <th
                        className='py-1 px-6 text-left cursor-pointer relative' onClick={() => {
                    settings.order !== OrderConstants.MARKET && dispatch(SetOrderAction(OrderConstants.MARKET))
                }}>M {settings.order === OrderConstants.MARKET &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                <th/>
                {/*market*/}
                <th className='py-1 px-3 text-center'>Market</th>
                {/*bookmaker*/}
                <th className='py-1 px-3 text-center cursor-pointer relative'
                    onClick={() => {
                        settings.order !== OrderConstants.BOOK && dispatch(SetOrderAction(OrderConstants.BOOK))
                    }}>Book
                    {settings.order === OrderConstants.BOOK &&
                            <div className='absolute 2xl:right-10 right-7 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                {/*odd*/}
                <th className='py-1 text-center'>Odds</th>
                {/*comparison*/}
                <th className='py-1  text-center cursor-pointer relative'
                    onClick={() => {
                        settings.order !== OrderConstants.COMPARISON && dispatch(SetOrderAction(OrderConstants.COMPARISON))
                    }}>Comparison
                    {settings.order === OrderConstants.COMPARISON &&
                            <div className='absolute right-2 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                {/*betfair cap*/}
                <th/>
                {/*chart + moneyline*/}
                <th colSpan={2}>TOT</th>
                {/*source + actions*/}
                <th colSpan={2}>
                    <p className='2xl:text-base px-2 text-monitorSm text-black float-left bg-white w-24'>{showedAlerts}</p>
                </th>
            </tr>
            </thead>
        </table>
        <div style={{
            background: `url('${process.env.PUBLIC_URL}/static/img/table_background.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}
             id='monitorTable'
             className='2xl:monitor-table-wrapper border-2 border-table-primary bg-table-monitor monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'>
            <table className='text-table-primary w-full'>
                <colgroup>
                    {/*event_name*/}
                    <col className='w-auto'/>
                    {/*search*/}
                    <col className='w-8'/>
                    {/*date*/}
                    <col className='w-[8%]'/>
                    {/*margin*/}
                    <col className='w-16'/>
                    {/* arrow */}
                    <col className='w-8'/>
                    {/*market*/}
                    <col className='w-[8%]'/>
                    {/*bookmaker*/}
                    <col className='w-[8%]'/>
                    {/*odd*/}
                    <col className='w-[10%]'/>
                    {/*comparison*/}
                    <col className='w-[10%]'/>
                    {/*betfair cap*/}
                    <col className='w-24'/>
                    {/*betfair chart*/}
                    <col className='w-16'/>
                    {/*moneyline*/}
                    <col className='w-24'/>
                    {/*source*/}
                    <col className='w-16'/>
                    {/*actions*/}
                    <col className='w-16'/>
                </colgroup>
                <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                {children}
                </tbody>
            </table>
        </div>
    </div>
}
