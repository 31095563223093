import {SetOrderAction} from '../../../store/actions/hotmatch/SetOrderAction';
import {useDispatch, useSelector} from 'react-redux';
import {HotMatchSettingsObject} from '../../../@types/response';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortDown} from '@fortawesome/free-solid-svg-icons'
import {OrderConstants} from "../../../constants/HotMatchConstants";
import {getHotMatchesLength} from "../../../store/selectors/hotmatch/getHotMatchesLength";
import {getSettings} from "../../../store/selectors/hotmatch/getSettings";


export default function HotMatchesTable({children}: { children?: any }) {

    const settings: HotMatchSettingsObject = useSelector(getSettings);
    const showedAlerts: number = useSelector(getHotMatchesLength);
    const dispatch = useDispatch();

    return <div className='w-full inline-block'>
        <table className='text-table-primary w-full table-fixed'>
            <colgroup>
                {/*event_name*/}
                <col className='w-auto'/>
                {/*search*/}
                <col className='w-8'/>
                {/*date*/}
                <col className='w-[10%]'/>
                {/*delta*/}
                <col className='w-16'/>
                {/*market*/}
                <col className='w-[10%]'/>
                {/*sign*/}
                <col className='w-[10%]'/>
                {/*odd*/}
                <col className='w-[10%]'/>
                {/*betfair cap*/}
                <col className='w-[6%]'/>
                {/*actions*/}
                <col className='w-[6%]'/>
            </colgroup>
            <thead>
            <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                <th className='text-left pl-16 py-1'>
                    Event/Competition
                </th>
                <th/>
                <th className='py-1 text-center cursor-pointer relative'
                    onClick={() => {
                        settings.order !== OrderConstants.DATE && dispatch(SetOrderAction(OrderConstants.DATE))
                    }}>Date {settings.order === OrderConstants.DATE &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                <th className='py-1 text-end cursor-pointer relative' onClick={() => {
                    settings.order !== OrderConstants.DELTA && dispatch(SetOrderAction(OrderConstants.DELTA))
                }}>Delta {settings.order === OrderConstants.DELTA &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                <th className='py-1 px-3 text-center'>Market</th>
                <th className='py-1 px-3 text-center'>Sign</th>
                <th className='py-1 text-center cursor-pointer relative' onClick={() => {
                    settings.order !== OrderConstants.ODD && dispatch(SetOrderAction(OrderConstants.ODD))
                }}>Odd {settings.order === OrderConstants.ODD &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                <th className='py-1 text-right cursor-pointer relative' onClick={() => {
                            settings.order !== OrderConstants.TOT && dispatch(SetOrderAction(OrderConstants.TOT))
                        }}>TOT
                    {settings.order === OrderConstants.TOT &&
                            <div className='absolute 2xl:right-12 right-8 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                <th>
                    <p className='2xl:text-base px-2 mx-auto w-20 text-monitorSm text-black bg-white'>{showedAlerts}</p>
                </th>
            </tr>
            </thead>
        </table>
        <div style={{
            background: `url('${process.env.PUBLIC_URL}/static/img/table_background.jpg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}
             id='hotMatchTable'
             className='2xl:monitor-table-wrapper border-2 border-table-primary bg-table-monitor monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'>
            <table className='text-table-primary w-full'>
                <colgroup>
                    {/*event_name*/}
                    <col className='w-auto'/>
                    {/*search*/}
                    <col className='w-8'/>
                    {/*date*/}
                    <col className='w-[10%]'/>
                    {/*delta*/}
                    <col className='w-16'/>
                    {/*market*/}
                    <col className='w-[10%]'/>
                    {/*sign*/}
                    <col className='w-[10%]'/>
                    {/*odd*/}
                    <col className='w-[10%]'/>
                    {/*betfair cap*/}
                    <col className='w-[6%]'/>
                    {/*actions*/}
                    <col className='w-[6%]'/>
                </colgroup>
                <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                {children}
                </tbody>
            </table>
        </div>
    </div>
}
