import {
    BookmakerData,
    EventStoredObject,
    MarketCapData,
    MarketCapObject,
    MarketData,
    MoneyLineData,
    MoneyLineObject,
    MonitorSettingsObject,
} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import Odds from "./Odds";
import OddsComparison from "./OddsComparison";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {useContext, useEffect, useRef, useState} from "react";
import {ModalsContext} from "../modals/ModalsContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getSettings} from "../../../store/selectors/monitor/getSettings";
import {
    faChartArea,
    faChartLine,
    faEquals,
    faEye,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faSearch,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {getMarkets} from "../../../store/selectors/getMarkets";
import {AVERAGE, BETFAIR, getMoneyLine, PINNACLE} from "../../../constants/CommonConstants";
import {DeleteAlertAction} from "../../../store/actions/monitor/DeleteAlertAction";
import Tooltip from "../common/Tooltip";
import {EventModalProps} from "../../../@types/inputs";
import {getMarketCap} from "../../../store/selectors/getMarketCap";
import {getEventsMoneyLine} from "../../../store/selectors/getEventsMoneyLine";
import {getBetfairChartUrl} from "../../../store/utils/monitorUtils";
import {toMoney} from "../../../store/utils/commonUtils";

export default function MonitorItem({alert, event}: { alert: any; event: EventStoredObject }) {
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const markets: MarketData = useSelector(getMarkets);

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);
    const [showSelectionChart, setShowSelectionChart] = useState<boolean>(false);

    const marketMoneyLines: MoneyLineObject = useSelector(getEventsMoneyLine);
    const [marketMoneyLine, setMarketMoneyLine] = useState<MoneyLineData>()
    const prevMarketMoneyLine = useRef(marketMoneyLine);
    const [animationMarketMoneyLine, setAnimationMarketMoneyLine] = useState("");

    const marketCaps: MarketCapObject = useSelector(getMarketCap);
    const [marketCap, setMarketCap] = useState<MarketCapData>();
    const prevMarketCap = useRef(marketCap);
    const [animationMarketCap, setAnimationMarketCap] = useState("");

    useEffect(() => {
        const mcv = marketCaps[`${alert.id}-${alert.marketId}-${alert.signId}`]
        if (prevMarketCap.current !== mcv) {
            prevMarketCap.current = mcv
            setMarketCap(mcv)
            setAnimationMarketCap("animate-pop")
            const timeout = setTimeout(() => setAnimationMarketCap(""), 300)
            return () => clearTimeout(timeout)
        }
    }, [marketCaps]);

    useEffect(() => {
        const mlv = marketMoneyLines[`${alert.id}`]
        if (prevMarketMoneyLine.current !== mlv) {
            prevMarketMoneyLine.current = mlv
            setMarketMoneyLine(mlv)
            setAnimationMarketMoneyLine("animate-pop")
            const timeout = setTimeout(() => setAnimationMarketMoneyLine(""), 300)
            return () => clearTimeout(timeout)
        }
    }, [marketMoneyLines]);

    const startDt = new Date(event.date);
    const betfairChartUrl = getBetfairChartUrl(alert, event)
    const hasBetfair = betfairChartUrl != null
    const comparisonOdd = alert.comparison.find((o: any) => o.signId === alert.signId) ?? null

    const dispatch = useDispatch()
    let arrow;

    if (comparisonOdd && comparisonOdd.firstBackOdd) {
        if (comparisonOdd.firstBackOdd === comparisonOdd.odd) {
            arrow = faEquals;
        } else {
            arrow = comparisonOdd.firstBackOdd < comparisonOdd.odd ? faLongArrowAltUp : faLongArrowAltDown;
        }
    }

    const label = `${event.category} - ${event.tournament}`
    const settings: MonitorSettingsObject = useSelector(getSettings)
    let best = "border-monitor-margin-l2"
    if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level1) {
        best = "border-monitor-margin-l0"
    } else if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level0) {
        best = "border-monitor-margin-l1"
    }

    return (
            <tr
                    id={`alert-${alert.id}-${alert.marketId}-${alert.signId}`}
                    key={`alert-${alert.id}-${alert.marketId}-${alert.signId}`}
                    className={`border-b-2 tracking-[1px] border-black hover:bg-gray-600
            ${alert.comparisonType === AVERAGE ? "bg-monitor-average" : ""}
            ${alert.comparisonType === BETFAIR ? "bg-monitor-betfair" : ""}
            ${alert.comparisonType === PINNACLE ? "bg-monitor-pinnacle" : ""}`}
            >
                {/* event_name */}
                <td className="items-center 2xl:pl-28 pl-16 relative">
                    <div className={"border-r-[5px] absolute -left-2 2xl:mx-14 mx-8 2xl:h-12 h-9 " + best}></div>
                    <Tooltip isEvent={true} tooltipText={`${event.name} ${label}`}>
                        <div className="flex flex-col py-1 2xl:max-w-[30rem] max-w-xs">
                            <div
                                    className="text-left text-white truncate 2xl:text-monitorBase text-[0.68rem] font-medium "
                                    data-id="event-name"
                                    data-value={event.name}
                            >
                                {event.name}
                            </div>

                            <div
                                    className="text-left 2xl:text-monitorBase truncate text-[0.68rem]"
                                    data-id="competition"
                                    data-value={label.substring(0, 32)}
                            >
                                {label.substring(0, 32)}
                            </div>
                        </div>
                    </Tooltip>
                </td>
                {/*search*/}
                <td>
                <span
                        className="cursor-pointer"
                        onClick={() => {
                            const _event: EventModalProps = {id: event._id, name: event.name, sportId: event.sportId};
                            setModalsDataCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, {event: _event});
                            setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, true);
                        }}
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </span>
                </td>
                {/* data */}
                <td
                        className="py-1 px-3 text-center"
                        data-id="event-time"
                        data-value={startDt.getTime()}
                >
                    {[
                        startDt.getDate().toString().padStart(2, "0"),
                        (startDt.getMonth() + 1).toString().padStart(2, "0"),
                    ].join("/")}
                    &nbsp;&nbsp;
                    {`${startDt.getHours().toString().padStart(2, "0")}:${startDt
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`}
                </td>
                {/* margin */}
                <td
                        className="py-1 px-2 text-center text-white bg-monitor-margin"
                        data-id="margin"
                        data-value={alert.margin.toFixed(1) || "-"}
                >
                    {alert.margin.toFixed(1) || "-"}
                </td>
                {/* arrow */}
                <td className="py-1 whitespace-nowrap">
                    {(arrow && comparisonOdd && (
                                    <Tooltip isEvent={false} tooltipText={`${comparisonOdd.firstBackOdd}`}>
                                        <div className="px-2">
                                            <FontAwesomeIcon icon={arrow}/>
                                        </div>
                                    </Tooltip>
                            )) ||
                            null}
                </td>
                {/* market */}
                <td className="py-1 px-3 text-center">
                    {alert.sbv != null ? <i>({alert.sbv})&nbsp;</i> : null}
                    {markets[alert.marketId.toString()].name}
                </td>
                {/* bookmaker */}
                <td className="py-1 px-3 text-center tracking-normal font-semibold">
                    {bookmakers[alert.bookmakerId]?.name || alert.bookmakerId + " not found"}
                </td>
                {/* odds */}
                <td className="px-2 text-center relative btn-on-over">
                    <Odds odds={alert.odds} alert={alert} event={event} playability={alert.playability}/>
                    <div
                            className="absolute right-1 top-2 cursor-pointer text-red-500 btn-remove"
                            onClick={() =>
                                    dispatch(
                                            DeleteAlertAction({
                                                eventId: alert.id,
                                                marketId: alert.marketId,
                                                bookmakerId: alert.bookmakerId,
                                            })
                                    )
                            }
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </td>
                {/* comparison */}
                <td className="px-2 text-center relative btn-on-over">
                    <OddsComparison odds={alert.comparison} event={event} marketId={alert.marketId}/>
                </td>
                {/*betfair cap*/}
                <td className={`text-right 2xl:text-xs text-[.6rem] ${animationMarketCap}`}>
                    <div>{marketCap ? toMoney(marketCap.m) : ""}</div>
                    <div>{marketCap ? `[${toMoney(marketCap.r)}]` : ""}</div>
                </td>
                {/*betfair chart*/}
                <td className={`cursor-pointer text-center ml-2 ${hasBetfair}`}>
                    <FontAwesomeIcon
                            className={hasBetfair ? "" : "text-gray-400"}
                            icon={faChartArea}
                            onMouseEnter={() => hasBetfair && setShowSelectionChart(true)}
                            onMouseLeave={() => hasBetfair && setShowSelectionChart(false)}
                    />
                    {showSelectionChart && hasBetfair && (
                            <img
                                    className="fixed"
                                    alt="MarketCap"
                                    style={{zIndex: 9, left: 0, right: 0, top: 0, bottom: 0, margin: "auto"}}
                                    src={betfairChartUrl}
                            />
                    )}
                </td>
                {/*moneyline*/}
                <td className={`cursor-pointer items-center px-1 ${animationMarketMoneyLine}`}>
                    {marketMoneyLine ? getMoneyLine(alert.marketId, marketMoneyLine) : null}
                    <FontAwesomeIcon
                            className="ml-2 text-monitor-secondary"
                            icon={faChartLine}
                            onClick={() => {
                                setModalsDataCallback(MODALS_TYPES.SIGN_CHART_MODAL, {
                                    odd: {
                                        eventId: alert.id,
                                        bookmakerId: PINNACLE,
                                        marketId: alert.marketId,
                                        spread: alert.sbv,
                                        signId: alert.signId,
                                    },
                                    event: {
                                        id: event._id,
                                        name: event.name
                                    }
                                });
                                setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, true);
                            }}
                    />
                </td>
                {/*source*/}
                <td className="text-center" data-id="comparison" data-value={alert.comparisonType}>
                <span className="border-2 border-monitor-secondary font-semibold  px-1 ">
                    {alert.comparisonType === BETFAIR ? "BF" : ""}
                    {alert.comparisonType === AVERAGE ? "AVG" : ""}
                    {alert.comparisonType === PINNACLE ? "PIN" : ""}
                </span>
                </td>
                {/*actions*/}
                <td
                        className="cursor-pointer"
                        onClick={() => {
                            setModalsDataCallback(MODALS_TYPES.HIDE_ALERT_MODAL, {alert, event});
                            setOpenedModalsCallback(MODALS_TYPES.HIDE_ALERT_MODAL, true);
                        }}
                >
                    <FontAwesomeIcon icon={faEye}/>
                </td>
            </tr>
    );
}
