import {skeletonGet} from './skeleton/skeletonGet';
import {skeletonPost} from "./skeleton/skeletonPost";
import {DeleteAlertMarketPayload, HideAlertPayload, SearchEventPayload, UserExtraPayload} from "../@types/actions";
import {skeletonDelete} from "./skeleton/skeletonDelete";

const extraEndpoint = 'extra'

export const GetExtraAlertsCall = (): Promise<any> => {
    return skeletonGet('extra', extraEndpoint);
};

export const DeleteAlertCall = (payload: DeleteAlertMarketPayload): Promise<any> => {
    return skeletonDelete(`${payload.eventId}/${payload.bookmakerId}/${payload.marketId}`, extraEndpoint);
};

export const HideAlertCall = (payload: HideAlertPayload): Promise<any> => {
    return skeletonPost(payload, `hide/${payload.eventId}`, extraEndpoint);
};

export const GetSettingsCall = (template: string = 'default'): Promise<any> => {
    return skeletonGet(`template/${template}`, extraEndpoint);
};

export const StoreSettingCall = (payload: UserExtraPayload): Promise<any> => {
    return skeletonPost(payload, `template/${payload.name}`, extraEndpoint);
};

export const DeleteSettingCall = (template: string): Promise<any> => {
    return skeletonDelete(`template/${template}`, extraEndpoint);
};

export const GetExtraEventCall = ({eventId}: { eventId: number }): Promise<any> => {
    return skeletonGet(`extra/${eventId}`, extraEndpoint);
};

export const GetExtraSpreadsCall = (eventId: number, bookmakerId: number, marketId: string): Promise<any> => {
    return skeletonGet(`extra/${eventId}/bookmaker/${bookmakerId}/market/${marketId}`, extraEndpoint);
};


export const SearchExtraEventCall = (payload: SearchEventPayload): Promise<any> => {
    return skeletonGet(`extra/search/${payload.event}`, extraEndpoint);
};