import {SetOrderAction} from '../../../store/actions/player/SetOrderAction';
import {useDispatch, useSelector} from 'react-redux';
import {PlayerSettingsObject} from '../../../@types/response';
import {getSettings} from '../../../store/selectors/player/getSettings';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons'
import {OrderConstants} from "../../../constants/PlayerConstants";
import {getMonitorsLength} from "../../../store/selectors/player/getMonitorsLength";


export default function PlayerTable({children}: { children?: any }) {

    const settings: PlayerSettingsObject = useSelector(getSettings);
    const showedAlerts: number = useSelector(getMonitorsLength);
    const dispatch = useDispatch();

    return <div className='2xl:monitor-table-header-wrapper'>
        <table className='text-table-primary w-full '>
            <colgroup>
                {/*event_name_player*/}
                <col className='w-auto'/>
                {/*search*/}
                <col className='w-8'/>
                {/*date*/}
                <col className='w-[8%]'/>
                {/*margin*/}
                <col className='w-16'/>
                {/*market*/}
                <col className='w-[8%]'/>
                {/*sign*/}
                <col className='w-[8%]'/>
                {/*bookmaker*/}
                <col className='w-[8%]'/>
                {/*odd*/}
                <col className='w-[10%]'/>
                {/*comparison*/}
                <col className='w-[10%]'/>
                {/*moneyline*/}
                <col className='w-24'/>
                {/*betfair chart*/}
                <col className='w-16'/>
                {/*source*/}
                <col className='w-16'/>
                {/*actions*/}
                <col className='w-16'/>
            </colgroup>
            <thead>
            <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                {/* event_name_player */}
                <th className='2xl:pl-28 pl-16 py-1 text-left'>Event/Competition</th>
                {/*search*/}
                <th/>
                {/* data */}
                <th className='py-1 text-center cursor-pointer relative' onClick={() => {
                    settings.order !== OrderConstants.DATE && dispatch(SetOrderAction(OrderConstants.DATE))
                }}>Date {settings.order === OrderConstants.DATE &&
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div>
                }
                </th>
                {/* margin */}
                <th className='py-1 px-6 text-left cursor-pointer relative' onClick={() => {
                    if (settings.order !== OrderConstants.MARGIN) {
                        dispatch(SetOrderAction(OrderConstants.MARGIN))
                    } else if (settings.order === OrderConstants.MARGIN) {
                        dispatch(SetOrderAction(OrderConstants.MARGIN_REV))
                    }
                }}>M {settings.order === OrderConstants.MARGIN ?
                        <div className='absolute 2xl:right-12 right-8 top-0'>
                            <FontAwesomeIcon icon={faSortDown}/>
                        </div> : settings.order === OrderConstants.MARGIN_REV ?
                                <div className='absolute 2xl:right-12 right-8 top-0'>
                                    <FontAwesomeIcon icon={faSortUp}/>
                                </div> : ''
                }
                </th>
                {/* market */}
                <th className='py-1 px-3 text-center'>Market</th>
                {/* sign */}
                <th className='py-1 px-3 text-center'>Sign</th>
                {/* bookmaker */}
                <th className='py-1 px-3 text-center cursor-pointer relative'
                    onClick={() => {
                        settings.order !== OrderConstants.BOOK && dispatch(SetOrderAction(OrderConstants.BOOK))
                    }}>Book
                    {settings.order === OrderConstants.BOOK &&
                            <div className='absolute 2xl:right-10 right-7 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                {/* odds */}
                <th className='py-1 text-center'>Odds</th>
                {/* comparison */}
                <th className='py-1  text-center cursor-pointer relative'
                    onClick={() => {
                        settings.order !== OrderConstants.COMPARISON && dispatch(SetOrderAction(OrderConstants.COMPARISON))
                    }}>Comparison
                    {settings.order === OrderConstants.COMPARISON &&
                            <div className='absolute right-2 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                {/*moneyline + betfair cap*/}
                <th colSpan={2}>TOT</th>
                {/*source + actions*/}
                <th colSpan={2}>
                    <p className='2xl:text-base px-2 text-monitorSm text-black float-left bg-white w-24'>{showedAlerts}</p>
                </th>
            </tr>
            </thead>
        </table>
        <div style={{
            background: `url('${process.env.PUBLIC_URL}/static/img/table_background.jpg')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        }}
             id='playerTable'
             className='2xl:monitor-table-wrapper border-2 border-table-primary bg-table-monitor monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'>
            <table className='text-table-primary w-full'>
                <colgroup>
                    {/*event_name_player*/}
                    <col className='w-auto'/>
                    {/*search*/}
                    <col className='w-8'/>
                    {/*date*/}
                    <col className='w-[8%]'/>
                    {/*margin*/}
                    <col className='w-16'/>
                    {/*market*/}
                    <col className='w-[8%]'/>
                    {/*sign*/}
                    <col className='w-[8%]'/>
                    {/*bookmaker*/}
                    <col className='w-[8%]'/>
                    {/*odd*/}
                    <col className='w-[10%]'/>
                    {/*comparison*/}
                    <col className='w-[10%]'/>
                    {/*moneyline*/}
                    <col className='w-24'/>
                    {/*betfair chart*/}
                    <col className='w-16'/>
                    {/*source*/}
                    <col className='w-16'/>
                    {/*actions*/}
                    <col className='w-16'/>
                </colgroup>
                <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                {children}
                </tbody>
            </table>
        </div>
    </div>
}
