import {
    AlertExtraObject,
    EventExtraStoredObject,
    EventsStoredObjects,
    ExtraBookmakerStoredObject,
    ExtraMainMarket,
    ExtraOddsObject,
    ExtraSettingsObject,
    FixtureObject,
    HiddenObject,
    SportsFilter
} from "../../@types/response";
import {
    AVERAGE,
    COMPARISONS,
    COMPARISONS_PRIORITY,
    EXTRA_MARKETS,
    MARGIN_LIMIT,
    MarginMethod
} from "../../constants/CommonConstants";
import {OrderConstants} from "../../constants/ExtraConstants";
import {getBetfairChart} from "./events";

/**
 * Sort Events
 *
 * @param a
 * @param b
 * @param events
 * @param settings
 */
export const sortEvent = (a: AlertExtraObject, b: AlertExtraObject, events: EventsStoredObjects, settings: ExtraSettingsObject): number => {
    switch (settings.order) {
        case OrderConstants.DATE:
            if (events[a.id].date !== events[b.id].date) {
                return events[a.id].date - events[b.id].date
            } else {
                return a.margin - b.margin
            }
        case OrderConstants.MARKET:
            if (a.margin !== b.margin) {
                return a.margin - b.margin
            } else {
                return events[a.id].name > events[b.id].name ? 1 : -1
            }
        case OrderConstants.COMPARISON:
            if (a.comparisonType === b.comparisonType) {
                return a.margin - b.margin
            }
            return COMPARISONS_PRIORITY[a.comparisonType.toString()] - COMPARISONS_PRIORITY[b.comparisonType.toString()]
        case OrderConstants.BOOK:
            if (a.bookmakerId === b.bookmakerId) {
                return a.margin - b.margin
            } else {
                return a.bookmakerId > b.bookmakerId ? 1 : -1;
            }
        case OrderConstants.NONE:
        default:
            return 0
    }
}

/**
 * Generate Betfair URL
 *
 * @param alert
 * @param event
 *
 * @return string | null
 */
export const getBetfairChartUrl = (alert: AlertExtraObject, event: EventExtraStoredObject): string | null => {
    if (!event.marketCap) return null
    try {
        const bMarketId = event.marketCap[alert.marketId]['bMarketId'].toString();
        const bSelectionId = event.marketCap[alert.marketId][alert.signId.toString()].toString();
        return getBetfairChart(bMarketId, bSelectionId)
    } catch (e) {
        return null
    }
}

/**
 * Apply Filters
 *
 * @param filter
 * @param sports
 * @param settings
 */
export const calculateFilter = (filter: SportsFilter, sports: FixtureObject, settings: SportsFilter): SportsFilter => {
    const result: SportsFilter = {...settings}
    Object.keys(filter).forEach(sportId => {
        if (result[sportId] === true) {
            result[sportId] = Object.fromEntries(Object.keys(sports[sportId]).map(k => [k, true]))
        }
        if (filter[sportId] === true || Object.keys(filter[sportId]).length === 0) {
            result[sportId] = filter[sportId]
            return
        } else if (!(sportId in result)) {
            result[sportId] = {}
        }
        Object.keys(filter[sportId]).forEach(categoryId => {
            // @ts-ignore
            result[sportId][categoryId] = filter[sportId][categoryId]
        })
    })
    return result
}


/**
 * eventsListener
 *
 * @returns AlertExtraObject
 * @param event
 * @param settings
 * @param booked
 * @param hides
 */
export function processEvent(event: EventExtraStoredObject, settings: ExtraSettingsObject, booked: string[], hides: HiddenObject): AlertExtraObject[] {
    let alerts: any = [];
    try {

        if (!('bookmakers' in event) || !settings /*|| !isVisible(event, settings.sports)*/) {
            return []
        }

        const expire = new Date()
        expire.setHours(0)
        expire.setMinutes(0)
        expire.setSeconds(0)
        expire.setDate(expire.getDate() + settings.date)

        const startdt = new Date(event.date)
        if ((startdt < new Date()) || (settings.date > 0 && startdt >= expire)) {
            return []
        }

        const bookmakers: any = event.bookmakers
        const bookmakerToCompare: any = Object.keys(event.bookmakers || {})
            .filter((bookmakerId) => settings.bookmakers.includes(parseInt(bookmakerId)) && !COMPARISONS.includes(parseInt(bookmakerId)))
            .map(bookmakerId => Object.assign({}, {id: bookmakerId, markets: bookmakers[bookmakerId]}))

        //Start AVERAGE calculation
        const averageBookmakers = settings.comparisons[AVERAGE.toString()]?.bookmakers
        const activeBookmakers: ExtraBookmakerStoredObject[] = Object.keys(event.bookmakers || {})
            .filter((bookmakerId) => !COMPARISONS.includes(parseInt(bookmakerId)))
            .filter((bookmakerId) => averageBookmakers?.length ? averageBookmakers.includes(parseInt(bookmakerId)) : false)
            .map((bookmakerId) => {
                return {id: bookmakerId, markets: event.bookmakers[bookmakerId]}
            })
        if (settings.comparisons[AVERAGE.toString()]?.status && activeBookmakers.length > 0) {
            const bComparison = getExtraAverageBookmaker(event._id, activeBookmakers)
            alerts = alerts.concat(
                processExtraComparison(event._id, bComparison, bookmakerToCompare, settings, booked, hides)
            )
        }
    } catch (error) {
        console.error('Error on process event', error, event)
    }
    return alerts
}


/**
 * get Average Bookmakers
 *
 * @returns {}
 * @param eventId
 * @param bookmakers
 */
export function getExtraAverageBookmaker(eventId: number, bookmakers: ExtraBookmakerStoredObject[]) {
    const bookmakersMarkets: Set<string> = new Set(bookmakers.flatMap(b => Object.keys(b.markets)))
    let markets: any = {}
    bookmakersMarkets.forEach((marketKey) => {

        const sbvs: Set<string> = new Set(bookmakers.flatMap(b => Object.keys(b.markets?.[marketKey] || {})))
        let signs: string[] = Object.keys(EXTRA_MARKETS[marketKey]?.outcomes ?? [])

        sbvs.forEach(sbv => {
            let count: { [signId: string]: number } = signs.reduce((obj, item) => {
                return {...obj, [item]: 0};
            }, {}), sum: { [signId: string]: number } = Object.assign({}, count)

            let signAverage: any = {}
            for (const bookmaker of bookmakers) {
                if (signs.length === 0) {
                    signs = Object.keys(bookmaker.markets[marketKey]?.[sbv] || {})
                }
                let bookmakerSigns = bookmaker.markets[marketKey]?.[sbv]
                if (!bookmakerSigns) continue
                for (const signId of signs) {
                    let backOdd = bookmakerSigns[signId]?.odd
                    if (backOdd > 1) {
                        sum[signId] += backOdd
                        count[signId]++
                    }
                }
            }

            for (const signId of signs) {
                signAverage[signId] = {
                    outcomePosition: signId,
                    odd: count[signId] ? Math.round((sum[signId] / count[signId]) * 100) / 100 : 1,
                    firstBackOdd: 100,
                    updt: new Date().getTime()
                }
            }

            if (!markets[marketKey]) markets[marketKey] = {}
            markets[marketKey][sbv] = signAverage
        })
    })

    return {
        id: eventId.toString(),
        eventId: eventId,
        playability: 1,
        comparisonType: AVERAGE,
        markets
    }
}

/**
 * Process Comparison
 *
 * @param eventId
 * @param comparison
 * @param bookmakers
 * @param settings
 * @param booked
 * @param hides
 */
export function processExtraComparison(eventId: number, comparison: ExtraBookmakerStoredObject, bookmakers: ExtraBookmakerStoredObject[], settings: ExtraSettingsObject, booked: string[], hides: HiddenObject): AlertExtraObject[] {
    // @ts-ignore
    return Object.keys(comparison.markets)
        .map((marketId: string) => Object.entries(comparison.markets[marketId])
            .flatMap(([sbv, signs]) => Object.entries(signs)
                .map(([signId, sign]) => Object.assign(sign, {signId, sbv}))
                .filter((sign: any) => !booked.includes(`${eventId}-${marketId}-${sign.sbv}-${sign.signId}`))
                .map((sign: any) => processExtraBookmakers(eventId, comparison, bookmakers, marketId.toString(), sign.sbv.toString(), sign.signId.toString(), settings))
                .filter((alert: any) => alert != null && (
                    !Object.keys(hides).includes(`${eventId}-${alert.marketId}-${sbv}-${alert.signId}`) || hides[`${eventId}-${alert.marketId}-${sbv}-${alert.signId}`] > alert.margin
                ))
            )
        )
        .filter((x: any) => x.length)
        .flat();
}


/**
 * Process Bookmakers
 *
 * @param eventId
 * @param comparison
 * @param bookmakers
 * @param marketId
 * @param sbv
 * @param signId
 * @param settings
 */
function processExtraBookmakers(eventId: number, comparison: ExtraBookmakerStoredObject, bookmakers: ExtraBookmakerStoredObject[], marketId: string, sbv: string, signId: string, settings: ExtraSettingsObject): null | AlertExtraObject {
    return bookmakers
        .map((bookmaker: ExtraBookmakerStoredObject) => {
            //Has Market and SBV
            if (!bookmaker.markets || !(marketId in bookmaker.markets) || !(sbv in bookmaker.markets[marketId])) return null
            // Get comparison odd
            const compOdd = comparison.markets[marketId][sbv][signId].odd
            if (compOdd <= 1) return null
            //Get bookamker odd
            const bookOdd = bookmaker.markets[marketId][sbv][signId]?.odd
            if ((bookOdd && bookOdd < settings.from) || (settings.to !== 1 && bookOdd > settings.to)) return null
            //Apply bound
            const comparisonSettings = settings.comparisons[AVERAGE]
            const bookins = getExtraBookmakerBooking(comparison, marketId, sbv)
            const margin = calculateMargin(
                compOdd,
                bookOdd,
                comparisonSettings.margin,
                comparisonSettings.method,
                bookins,
                Object.keys(comparison.markets[marketId][sbv]).length
            )
            if (margin > comparisonSettings.threshold) return null
            return {
                id: eventId,
                comparisonType: AVERAGE,
                marketId: marketId,
                signId: signId,
                sbv: sbv,
                bookmakerId: parseInt(bookmaker.id),
                playability: 1,
                margin: margin,
                odds: getOdds(bookmaker, marketId, sbv),
                comparison: getOdds(comparison, marketId, sbv)
            }
        })
        .filter((alert: any) => alert?.margin && alert.margin < MARGIN_LIMIT)
        .sort((a: any, b: any) => a.margin - b.margin)
        .find((_, idx) => idx === 0) || null
}

function getExtraBookmakerBooking(bookmaker: any, marketId: string, sbv: string) {
    const signs = bookmaker.markets[marketId][sbv]
    let bookings = 0
    for (const signId of Object.keys(signs)) {
        bookings += 100 / signs[signId].odd
    }
    return bookings
}

/**
 * Get Odds
 *
 * @param bookmaker
 * @param marketId
 * @param sbv
 * @returns {[]}
 */
function getOdds(bookmaker: any, marketId: string, sbv: string): ExtraOddsObject[] {
    const marketOdds = Object.assign({}, bookmaker.markets)[`${marketId}`][sbv] || {}

    return Object
        .keys(marketOdds)
        .map((signId) => {
            return {
                signId: signId,
                sign: EXTRA_MARKETS[marketId]?.outcomes?.[signId]?.name ?? signId,
                odd: Math.round(marketOdds[signId]?.odd * 100) / 100 || 0,
                firstBackOdd: marketOdds[signId]?.firstBackOdd || 0,
                sbv: sbv
            }
        })
}


/**
 * Compare Bookmaker
 *
 * @returns {number}
 * @param comparisonOdd
 * @param bookmakerOdd
 * @param margin
 * @param method
 * @param bookings
 * @param signs
 */
function calculateMargin(comparisonOdd: number, bookmakerOdd: number, margin: null | number, method: null | MarginMethod, bookings: number, signs: number): number {
    const boundComparisonOdd = (margin === null) ? comparisonOdd : 100 / ((100 / comparisonOdd) - ((bookings - margin) / signs))
    let result = (method === MarginMethod.NEW) ? ((100 / bookmakerOdd) - (100 / boundComparisonOdd)) : (boundComparisonOdd - bookmakerOdd) / (boundComparisonOdd / 100)
    return Math.round(result * 10) / 10
}

/**
 *
 * @param markets
 */
export function getMain(markets: ExtraMainMarket): string {
    const mm = Object.keys(markets)
    if (mm.length !== 1) {
        throw new Error("Only one Main market is allowed here")
    }
    return mm[0]
}